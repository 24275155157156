<div class="navigation-mobile">
    <wuc-navigation-mobile>
        <wuc-navigation-mobile-item icon="house" name="Home" route="/"></wuc-navigation-mobile-item>
        <wuc-navigation-mobile-item
            icon="car"
            name="Versicherungen"
            route="/{{ appRoutesEnum.Insurances }}"
        ></wuc-navigation-mobile-item>
        <wuc-navigation-mobile-item role="menu" name="Menu"></wuc-navigation-mobile-item>
        <wuc-navigation-mobile-item
            icon="insurances"
            name="Über InShared"
            url="/{{ appRoutesEnum.About }}"
        ></wuc-navigation-mobile-item>
        <wuc-navigation-mobile-item
            icon="reward"
            name="Jahresbelohnung"
            url="/{{ appRoutesEnum.AnnualReward }}"
        ></wuc-navigation-mobile-item>
        <wuc-navigation-mobile-item
            icon="emergency"
            name="Schaden"
            route="/{{ appRoutesEnum.Damage }}"
        ></wuc-navigation-mobile-item>
        <wuc-navigation-mobile-item
            icon="mail"
            name="Kontakt"
            route="/{{ appRoutesEnum.Contact }}"
        ></wuc-navigation-mobile-item>
        <wuc-navigation-mobile-item
            icon="user"
            name="Versicherungs&shy;mappe"
            route="/{{ appRoutesEnum.MyZone }}/{{ appRoutesEnum.Insurances }}"
        ></wuc-navigation-mobile-item>
    </wuc-navigation-mobile>
</div>
